import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import {  useSelector } from "react-redux"
import LoginForm from "../components/loginForm"

import { MessageList } from "../components/messageList"

const MesagesPage = () => {
  const {  email , messages } = useSelector(
    state => ({
      ...state.mainReducer,
      ...state.mainReducer.user.user,
    })
  )
  return (
    <>
      {email ? (
        <Layout>
          <CustomDiv>
            <MessageList messages={messages} />
          </CustomDiv>
        </Layout>
      ) : (
        <Layout>
          <LoginForm />
        </Layout>
      )}
    </>
  )
}
export default MesagesPage

//styled-components
const CustomDiv = styled.div`
  #from,
  #date {
    font-size: 12px;
    min-width: 100px;
    max-width: 100px;
  }
  #delete {
    max-width: 10px;
  }
  #message {
    min-width: 100px;
  }
  margin: 10px 10px 40px 10px;
  // height: 100vh;
  // border: 1px solid grey;
  // span {
  //   margin: 30px;
  // }
  .MuiCircularProgress-root {
    margin: auto 50%;
    color: black;
  }
`
