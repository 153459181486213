import React from "react"
import Delete from "@material-ui/icons/Delete"

import Layout from "../components/layout"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { deleteMessage } from "../redux/firebaseAction"
import moment from "moment"
import LoginForm from "../components/loginForm"
import { green } from "@material-ui/core/colors"
import { log } from "../components/helpers"
import Button from "@material-ui/core/Button"
import { Link } from "@reach/router"

import { CustomButton } from "../components/styles/styles"

export const MessageList = props => {
  const dispatch = useDispatch()
  const { messages } = props
  return (
    <table>
      <tbody>
        <CustomTr>
          <th>Inbox</th>
        </CustomTr>
        {messages &&
          messages.map((message, index) => {
            let sent_time = moment.unix(
              message.messageData.sent_at && message.messageData.sent_at.seconds
            )
            return (
              <>
                <MessageRow key={index}>
                  <div className="from">
                    <span>{message.messageData.from}</span>
                    <span>{sent_time.format("LLLL")}</span>
                  </div>
                  <div className="message">
                    <span>{message.messageData.message}</span>
                    <span
                      id="delete"
                      onClick={() => dispatch(deleteMessage(message.messageId))}
                    >
                      <Delete />
                    </span>
                  </div>
                </MessageRow>
              </>
            )
          })}
        <div style={{ textAlign: "end", marginTop: "10px" }}>
          <Link to="/sendMessage">
            <CustomButton
              pl="10px"
              pr="10px"
              mt="20px"
              color="white"
              bg="black"
            >
              <span>Send a message </span>
            </CustomButton>
          </Link>
        </div>
      </tbody>
    </table>
  )
}
//styled-components
const CustomDiv = styled.div`
  #from,
  #date {
    font-size: 12px;
    min-width: 100px;
    max-width: 100px;
  }
  #delete {
    max-width: 10px;
  }
  #message {
    min-width: 100px;
  }
  margin: 10px 10px 40px 10px;
  // height: 100vh;
  // border: 1px solid grey;
  // span {
  //   margin: 30px;
  // }
  .MuiCircularProgress-root {
    margin: auto 50%;
    color: black;
  }
`
const CustomTr = styled.tr`
  background-color: black;
  color: white;
  padding: 10px;
  span {
    margin: 30px;
  }
`

const MessageRow = styled.tr`
  border-bottom: 0.1px solid gray;
  color: black;
  padding: 10px;
  .message {
    display: flex;
    justify-content: space-between;
    padding: 1px 20px 20px 10px;
    font-size: 14px;
  }
  .from {
    color: black;
    background: #ececec;
    border-bottom: 0.5px dashed grey;
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 10px;
    font-size: 16px;
  }
  span {
    margin: 1px;
  }
`
